.save-box{
	padding-left: 25px;
	padding-right: 25px;
	padding-bottom: 5px;
	padding-top: 5px;
	/* width:100px; */
	border-radius: 10px;
	display: inline-block;
	margin-bottom: 10px;
}
.failed-box{
	background: rgba(255, 0, 0, .5);
	color:darkred;

}
.saving-box{
	background: rgba(244, 244, 244, .6);
	color:darkgray;

}
.saved-box{
	background: rgba(165, 221, 114, .5);
	color:black;

}
.reintentar-button{
	display: inline;
	background: transparent;
	border: 0;
	color: darkred;
	padding: 10px;
}